import { Report } from "notiflix/build/notiflix-report-aio";
import { Loading } from "notiflix";
import axios from "axios";
import { browserName, osName, deviceDetect, getUA } from "react-device-detect";
import ReactPixel from "react-facebook-pixel";
import { trackCompleteRegistration, trackLead } from "./handleTracking";

interface uploadInterface {
    id: string;
    files: any;
}

const getSubdomain = () => {
    const url = window.location.origin;
    let domain = url;
    if (url.includes("://")) domain = url.split("://")[1];
    const subdomains = domain.split(".");
    return !subdomains[0].includes("www") ? subdomains[0] : subdomains[1];
};

const getLeadSource = (subdomain: any) => {
    const leadSources: any = {
        fb: "Facebook",
        tk: "TikTok",
        in: "LinkedIn",
        ins: "Instagram",
        yt: "YouTube",
        claim: "any",
        nl: "Newsletter",
        localhost: "test",
    };
    return leadSources[subdomain];
};

export const uploadDataToServer = async (claimObject: any) => {
    console.log("This may take a minute.");
    Loading.standard("This may take a minute.");

    claimObject.device = osName;
    claimObject.browser = browserName;

    // SET TYPE OF DOCUMENTS TO CREATE......
    // claimObject.leadOrigin = "webform_OA";
    // claimObject.leadOrigin = 'webform_CCD'
    claimObject.leadOrigin = "webform_SENTINEL_ALTERNATE";
    claimObject.leadSource = getLeadSource(getSubdomain());

    // claimObject.lender = "james";
    const utms = localStorage.getItem("utms");
    if (utms) claimObject.utms = JSON.parse(utms);
    // console.log(claimObject)

    try {
        const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_UPLOAD_DATA_URL}`,
            data: claimObject,
            // headers: { "Content-Type": "multipart/form-data" },
        });

        trackLead({ email: claimObject.email, phone: claimObject.phone });

        console.log("DATA UPLOAD RESPONSE: ", response.data);
        Loading.remove();
        return { success: true, responseData: response.data };
    } catch (error: any) {
        console.log("ERROR IS : ", error);
        Loading.remove();

        if (error.response && error.response.data) {
            Report.info(
                "Additional Claims",
                "We only require an initial claim, any additional claims will be investigated at a later date via automated checks.",
                "Thank you",
                () => {
                    window.location.href = "https://sentinellegal.co.uk";
                }
            );
        } else {
            Report.failure("Error", "Please refresh the page and try again.", "okay");
        }
        return { success: false };
    }
};

export const uploadFileToServer = async (id: string, files: any) => {
    Loading.standard("Uploading files...");
    const formData = new FormData();

    let isID = false;
    formData.append("id", id);
    // formData.append("file", fileToUpload, "file");
    files.forEach((fileToAttach: any) => {
        formData.append(fileToAttach.fileInputName, fileToAttach.file);
        if (fileToAttach.fileInputName == "poid") {
            isID = true;
            formData.append("type", "webform_SENTINEL");
        }
    });
    try {
        const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_UPLOAD_FILES_URL}`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        });
        console.log("FILE UPLOAD RESPONSE: ", response.data);
        if (isID) trackCompleteRegistration();

        Loading.remove();
        console.log("RESPONSE : ", response);

        return response?.data;
    } catch (error: any) {
        Loading.remove();

        if (!error.response) {
            console.log("ERROR IS : ", error);
            if (error.message === "No file provided") {
                Report.failure("No file selected", "Please add a file and try again.", "okay");
            } else {
                Report.failure("Error Ocurred", "Please refresh the page and try again.", "okay");
            }
        } else {
            const errorMessage = error.response.data.error.message;
            if (errorMessage === "Token not found") {
                Report.failure("Broken Link Used", 'Please click the link found in your "CFA signed" email to upload your ID.', "okay");
            } else if (errorMessage === "Extension not accepted") {
                Report.failure("Incorrect file type", "Please check the file type and try again.", "okay");
            } else if (errorMessage === "File exceeds 20MB") {
                Report.failure("File exceeds 20MB", "Please use a smaller image and try again.", "okay");
            } else if (errorMessage === "Couldnt convert image.") {
                Report.failure("Use different Image", "Please use a standard image format (PNG, JPEG, HEIC)", "okay");
            } else {
                Report.failure("Error Ocurred", "Please refresh the page and try again.", "okay");
            }
        }

        return { success: false };
    }
};
