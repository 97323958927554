import { useState } from "react";
import styles from "./styles.module.scss";
import { FieldGroup, FormCard } from "../../reusable";
import lines from "../../../assets/lines2.svg";
import { FormPartInterface } from "../../../interfaces";

const getWidth = () => {
    const clientWidth = document.body.clientWidth;
    if (clientWidth > 720) {
        return 720;
    }
    if (clientWidth <= 720 && clientWidth > 580) {
        return 580;
    }
    if (clientWidth < 580) {
        return clientWidth - 90;
    }
    return 0;
};

export const Complete = (props: FormPartInterface) => {
    const { isActive } = props;
    const [currentStep, setCurrentStep] = useState(0);
    const [postCurrentStep, setPostCurrentStep] = useState(0);

    const width = getWidth();
    const height = Number(width / 1.8).toFixed(0);

    const getCurrentStep = () => {
        return isActive == "complete" ? (
            <FieldGroup title="Claim Complete">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    <h2
                        style={{
                            color: "#434343",
                            textAlign: "center",
                            marginTop: 0,
                            marginBottom: 0,
                        }}
                    >
                        Thank you!
                    </h2>
                    <p
                        style={{
                            color: "#434343",
                            textAlign: "center",
                            marginTop: "10px",
                            marginBottom: "30px",
                        }}
                    >
                        Your application has been successfully submitted.
                    </p>
                    <span
                        style={{
                            height: "2px",
                            width: "60px",
                            display: "block",
                            background: "#b3b3b3",
                            margin: "0 auto",
                        }}
                    ></span>
                    <p
                        style={{
                            textAlign: "center",
                            color: "#434343",
                            marginTop: "30px",
                            marginBottom: "0px",
                            fontWeight: "600",
                            letterSpacing: "1px",
                        }}
                    >
                        Find out what happens next by watching this video.
                    </p>
                    <div style={{ margin: "20px auto", width, height, textAlign: "center" }}>
                        <video
                            width={width}
                            height={height}
                            style={{ boxSizing: "border-box", border: "10px solid #c88f5f", borderRadius: "8px", objectFit: "fill" }}
                            controls
                            playsInline
                        >
                            <source src={"https://sentinelpublicfiles.s3.eu-west-2.amazonaws.com/videos/claim-submitted.mp4"} type="video/mp4" />
                        </video>
                    </div>
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        );
    };

    return getCurrentStep();
};
