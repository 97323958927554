import { SaveValueInterface } from "../interfaces";
import {
    requiredPersonFields,
    requiredContactFields,
    requiredFinanceFields,
    requiredSignatureFields,
    requiredFilesFields,
    requiredVehicleFields,
    requiredAddressFields,
} from "../data/requiredFields";
import { Report } from "notiflix/build/notiflix-report-aio";
// import { EnergyContract } from "../components/claimForm";

export const sectionValidation = (dataToValidate: SaveValueInterface, section: string) => {
    try {
        switch (section) {
            case "address":
                console.log("VALIDATE ADDRESS FIELDS...");
                requiredFieldSearch(requiredAddressFields, dataToValidate);
                return { success: true, error: "" };
                break;

            case "person":
                console.log("VALIDATE PERSON FIELDS...");
                requiredFieldSearch(requiredPersonFields, dataToValidate);
                return { success: true, error: "" };
                break;

            case "contact":
                console.log("VALIDATE CONTACT FIELDS ...");
                requiredFieldSearch(requiredContactFields, dataToValidate);
                return { success: true, error: "" };
                break;

            case "vehicle":
                console.log("VALIDATE VEHICLE FIELDS ...");
                requiredFieldSearch(requiredVehicleFields, dataToValidate);
                return { success: true, error: "" };
                break;
            case "lender":
                console.log("VALIDATE LENDER FIELDS ...");
                requiredFieldSearch(requiredFinanceFields, dataToValidate);
                return { success: true, error: "" };
                break;

            case "signature":
                console.log("VALIDATE SIGNATURE FIELDS ...");
                requiredFieldSearch(requiredSignatureFields, dataToValidate);
                return { success: true, error: "" };
                break;
            case "files":
                console.log("VALIDATE FILES FIELDS ...");
                requiredFieldSearch(requiredFilesFields, dataToValidate);
                return { success: true, error: "" };
                break;

            default:
                return { success: false, error: "" };
                break;
        }
    } catch (error: any) {
        console.log("ERROR VALIDATING SECTION...", error);
        Report.failure("Error", error.message, "OK");
        return { success: false, error: error.message };
    }
};
export const fieldValidation = (dataToValidate: SaveValueInterface, checkField = true) => {
    // console.log('FIELD VALIDATION FOR: ', dataToValidate);
    let error = "";
    const valueToValidate = String(dataToValidate.value).trim();
    const fieldToValidate = dataToValidate.field.trim();

    // if (checkField && requiredFields.some((f) => f === fieldToValidate) && !valueToValidate.length) return { success: false, error: 'Field must not be empty.' };

    if (fieldToValidate === "telephone" && valueToValidate.length < 1) {
        // Report.failure("Unable to Progress", 'Unfortunately, due to answering No, you have been deemed ineligble for us to continue a claim.', "OK", () => { window.location.href = "https://google.com" });
        return { success: false, error: "Invalid Telephone number" };
    }

    if (fieldToValidate === "email" && valueToValidate.length < 1) {
        // Report.failure("Unable to Progress", 'Unfortunately, due to answering No, you have been deemed ineligble for us to continue a claim.', "OK", () => { window.location.href = "https://google.com" });
        return { success: false, error: "Invalid email address" };
    }

    return { success: true, error: "" };
};

const requiredFieldSearch = (fieldArray: Array<string>, claimObject: any) => {
    if (!Object.keys(claimObject).length) throw new Error(`Please complete all required fields.`);
    var missingRequiredFields = fieldArray.reduce((result: Array<string>, obj: string) => {
        if (!Object.keys(claimObject).some((f) => f === obj) || (Object.keys(claimObject).some((f) => f === obj) && !claimObject[obj].length))
            result.push(obj);
        return result;
    }, []);
    // console.log(missingRequiredFields);
    if (missingRequiredFields.length > 0) throw new Error(`Please complete all required fields (${missingRequiredFields.join(", ")}).`);
};
