import React, { useEffect, useState } from 'react'
// import styles from "./styles.module.scss";
import { Report } from 'notiflix/build/notiflix-report-aio'

import { titleData } from '../../../data/titles'
import { contactPreferencesData } from '../../../data/contactPreferences'
import {
    InputFieldText,
    InputFieldSelect,
    FieldGroup,
    Field,
    SubmitButton,
    ButtonContainer,
    FieldContainer,
    FormCard,
} from '../../reusable'

import { FormPartInterface, SaveValueInterface } from '../../../interfaces'
import { Checkbox } from '../../reusable/checkbox'
import { InputFieldAddressLookup } from '../../reusable/inputFieldAddressLookup'
import { sectionValidation } from '../../../helpers'
import { getWidth } from '../../../helpers/screenWidth'

export const GetStarted = (props: FormPartInterface) => {
    const { saveValueFunct, isActive, assignGroup, validateSection } = props
    const [currentStep, setCurrentStep] = useState(0)
    const [postCurrentStep, setPostCurrentStep] = useState(0)
    const [stepData, setStepData] = useState<any>({})

    const width = getWidth()
    const height = Number(width / 1.8).toFixed(0)

    const animateBeforeAssign = (assignment: string) => {
        setTimeout(() => {
            assignGroup(assignment)
        }, 400)
    }

    const getCurrentStep = () => {
        return isActive == 'Get started' ? (
            <FieldGroup title="Contact Information">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    <h2
                        style={{
                            color: '#434343',
                            textAlign: 'center',
                            marginTop: '0',
                            marginBottom: '20px',
                        }}
                    >
                        Start your mis-sold motor finance claim with Sentinel Legal.
                    </h2>

                    <span
                        style={{
                            height: '2px',
                            width: '60px',
                            display: 'block',
                            background: '#b3b3b3',
                            margin: '0 auto',
                        }}
                    ></span>

                    <p
                        style={{
                            textAlign: 'center',
                            color: '#434343',
                            marginTop: '20px',
                            marginBottom: '0px',
                            fontWeight: '600',
                            letterSpacing: '1px',
                        }}
                    >
                        Watch our 1 minute video for brief explaination on the application process.
                    </p>
                    <div style={{ margin: '20px auto', width, height, textAlign: 'center' }}>
                        <video
                            width={width}
                            height={height}
                            style={{
                                boxSizing: 'border-box',
                                border: '10px solid #c88f5f',
                                borderRadius: '8px',
                                objectFit: 'fill',
                            }}
                            controls
                            playsInline
                        >
                            <source
                                src={'https://sentinelpublicfiles.s3.eu-west-2.amazonaws.com/videos/start-form.mp4'}
                                type="video/mp4"
                            />
                        </video>
                    </div>
                    <SubmitButton
                        text="Start Your Claim"
                        btnType="primary"
                        onClick={() => animateBeforeAssign('address')}
                    />
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        )
    }

    return getCurrentStep()
}
