export const getWidth = () => {
    const clientWidth = document.body.clientWidth
    if (clientWidth > 720) {
        return 720
    }
    if (clientWidth <= 720 && clientWidth > 580) {
        return 580
    }
    if (clientWidth < 580) {
        return clientWidth - 90
    }
    return 0
}
