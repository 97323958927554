import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import { Wrapper } from '../reusable'

export const ClaimBanner = () => {
    return (
        <div className={`${styles.formBanner} ${responsiveStyles.formBanner}`}>
            <Wrapper>
                <div>
                    <h1>
                        Make a <span>Mis-sold Motor Finance Claim</span>
                    </h1>
                    <p>Start your mis-sold motor finance claim with Sentinel Legal.</p>
                </div>
            </Wrapper>
        </div>
    )
}
